<template>
    <div class="recipe-details page">
        <b-container>
            <b-row class="recipe-details__current-recipe" v-if="currentRecipe!=null">                
                <b-col cols="12" md="6" lg="5" xl="4" v-if="currentRecipe.side_text!=null">
                    <div class="current-recipe__ingredients" v-inview="'fade-from-left'">
                        <div class="beer-offer">
                            <h6>Az ételhez ajánlott sörkülönlegesség:</h6>                            
                            <img :src="offerImg" :alt="currentRecipe.recipe_category.product.name" />
                            <div class="data">
                                <div class="name">Soproni Óvatos Duhaj {{currentRecipe.recipe_category.product.name}}</div>
                                <a :href="currentRecipe.recipe_category.product.shop_link" target="_blank" class="btn btn--gold btn--shop" @click="setGtmCart(currentRecipe.recipe_category.product.name)"><span>vásárlás</span></a>
                            </div>                            
                        </div>
                        <div v-html="currentRecipe.side_text"></div>
                    </div>
                </b-col>
                <b-col cols="12" class="d-md-none current-recipe__details current-recipe__details--mobile">
                    <div class="short-desc" v-html="currentRecipe.short_description"></div>  
                    <div class="desc" v-html="currentRecipe.description"></div>                      
                </b-col>
                <b-col cols="12" md="6" lg="7" xl="7" offset-xl="1" class="current-recipe__details" v-inview="'fade-from-right'">
                    <h1 class="name">{{ currentRecipe.name }}</h1>
                    <div class="tags">                        
                        <div class="other-tag" v-if="currentRecipe.recipe_tag">
                            <div v-if="currentRecipe.making_time"><span>{{currentRecipe.making_time}}</span></div>
                            <div v-if="currentRecipe.difficulty"><span>{{currentRecipe.difficulty}}</span></div>
                            <div v-for="tag in currentRecipe.recipe_tag" :key="tag.id"><span>{{tag.name}}</span></div>
                        </div>
                        <div v-else class="tags-inner">
                            <div v-if="currentRecipe.making_time"><span>{{currentRecipe.making_time}}</span></div>
                            <div v-if="currentRecipe.difficulty"><span>{{currentRecipe.difficulty}}</span></div>
                        </div>
                    </div>                   
                    <div 
                        class="current-recipe__img-video" 
                        id="crVideoWrap" 
                        :class="[{'hidePlayBtn': showVideo}, {'hasVideo': currentRecipe.video}]"                         
                    >
                        <img 
                            :src="src_url + itemSrc(currentRecipe.image, 'w1296h728')" 
                            :alt="currentRecipe.name" 
                            :srcset="currentRecipe.srcset"
                            sizes="(max-width: 767px) 100vw, 70%"
                            class="img-fluid main-img"
                            v-if="!showVideo && itemSrc(currentRecipe.image, 'w1296h728') != 'no-image'"
                        />       
                        <div class="video-layer" v-if="currentRecipe.video" @click="showVideo=true">
                            <button class="btn--play" ></button>
                        </div>                                         
                        <div class="video-wrap" v-if="showVideo && currentRecipe.video" >
                            <iframe 
                                :id="'youtube-player'"
                                frameborder="0" 
                                :height="youtubeHeight" 
                                :src="addYoutubeLink()"                                 
                                allowfullscreen        
                                                        
                            >
                            </iframe>    
                        </div>
                    </div>                                        
                    <div class="short-desc d-none d-md-block" v-html="currentRecipe.short_description"></div>  
                    <div class="desc d-none d-md-block" v-html="currentRecipe.description"></div>                      
                </b-col>
            </b-row>
        </b-container>
        <section class="recipe-details__recipe-offer recipe-offer--details-page" v-inview="'fade-up'">
            <b-container>
                <b-row >
                    <b-col cols="12">
                        <div class="title">
                            <h2 class="text--gold">További receptek</h2>
                            <img src="@/assets/images/brand/ovatos-duhaj-logo.svg" class="od-logo" alt="Óvatos Duhaj"/>
                        </div>            
                    </b-col>
                </b-row>
            </b-container>           
            <div class="recipe-offer__categories-row" v-inview="'fade-up'">                                       
                <div class="recipe-category-item" 
                    v-for="category in recipeCategories" :key="category.id"
                    @click="setFilteredCategory(category)"
                    :class="{'active': parseInt(selectedFilterId) === parseInt(category.id)}"
                >
                    <div>
                        <img :src="src_url + itemSrc(category.image, 'w160h160')" v-if="itemSrc(category.image, 'w160h160') != 'no-image'" />
                        <div class="name">{{category.name}}</div>
                    </div>                            
                </div>
            </div>             
            <b-container>
                <b-row class="recipe-cards-wrap row" v-if="recipes.length>0">
                    <b-col cols="12" md="6" v-for="recipe in recipes" :key="recipe.id">
                        <div class="recipe-item-wrap" >
                            <RecipeCard :recipe="recipe" v-inview="'fade-up'"/>
                        </div>                        
                    </b-col>                
                </b-row>      
                <div class="d-flex justify-content-center btn-wrap" v-inview="'fade-up'">
                    <router-link class="btn btn--gold-border btn--with-arrow" :to="{name:'RecipeAll'}" @click.native="setGTMRecipeAll()">
                        <span>vissza az összes recepthez</span>
                    </router-link>
                </div>
            </b-container>            
        </section>
        <!-- OTHERS IN THIS FAMILY -->
        <Brothers :brothers="brothers" v-if="brothers.length > 0" :currentProdFamily="currentProdFamily" v-inview="'fade-up'"/>     
        <PageLoading  /> 
    </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import viewMI from '@/mixins/viewMI.js'

import RecipeCard from '@/components/pages/recipe/RecipeItem.vue'
import Brothers from '@/components/pages/productDetails/Brothers.vue'
import PageLoading from '@/components/layout/PageLoading.vue'


export default {
    mixins: [apiH,viewMI],
    components:{        
        RecipeCard,
        Brothers,
        PageLoading,
        
    },
    data(){
        return {
            recipeCategories:[],
            recipes:[],
            recipesAll:[],
            selectedFilterId: null,
            currentRecipe: null,
            brothers:[],
            currentProdFamily: null,
            showVideo:false
        }
    },
    computed:{
        offerImg(){
            let _this = this
            
            if (_this.currentRecipe.recipe_category.product && _this.currentRecipe.recipe_category.product.card_image ){
                return _this.src_url + _this.itemSrc(_this.currentRecipe.recipe_category.product.card_image, 'w282h600')
            } else if(_this.currentRecipe.recipe_category.product.main_image ){
                return _this.src_url + _this.itemSrc(_this.currentRecipe.recipe_category.product.main_image, 'w282h600')
            } else {
                return null
            }            
            
        },
        youtubeHeight(){            
            let youtubeWidth = Math.floor(document.getElementById('crVideoWrap').clientWidth)

            return youtubeWidth / (16/9)
        }
    },    
    created(){        
        this.getRecipes()             
    },
    watch:{
        $route(){
            this.recipes = []
            this.recipesAll = []
            this.recipeCategories = []
            
            this.getRecipes()
            
        }
    },
    methods:{
        getRecipes(){
            let _this = this           

            _this.get('recipe',
                {
                    params: {                          
                        relations:1,                                            
                        where: [
                            ['where','status', '1'],
                            ['where','image!=', '0'],                                                                        
                        ],
                        order_by:[['sort_order', 'asc'],['created_at', 'desc']],
                        relations_params:{
                            recipe_category: {relations: 1}
                        }                               
                    }
                },
                function(resp){                                        
                    _this.recipesAll = resp.data.recipe    
                    
                    //collect the categories and find the current element
                    _this.recipesAll.forEach( element => {   
                        //find the current recipe
                        if (element.slug === _this.$route.params.recipe_slug){
                            _this.currentRecipe = element
                            _this.selectedFilterId = element.recipe_category_id

                            _this.getProdFamily()
                            _this.setMetas()
                        } 

                        if (_this.recipeCategories.length === 0){                            
                            _this.recipeCategories.push(element.recipe_category)                        
                        } else if ( element.recipe_category && element.recipe_category_id != 0) {
                            if( _this.recipeCategories.find( item => parseInt(item.id) === parseInt(element.recipe_category.id)) == undefined ){                            
                            _this.recipeCategories.push(element.recipe_category)
                            }
                        } 
                        
                    })       
                    
                    //recipe not found
                    if (_this.currentRecipe == null){
                        _this.$router.push({name: '404-page'})
                    } else {
                        _this.selectRecipesOffer()
                    }
                    
                }, function(err){
                    console.log(err);
                    _this.recipes = []
                    _this.recipesAll = []
                    _this.recipeCategories = []
                }
            )
            
        },            
        getCurrentRecipe(){
            let _this = this

            // console.log('getCurrentRecipe')

            _this.recipesAll.forEach( element => {   
                //find the current element
                if (element.slug === _this.$route.params.recipe_slug){
                    _this.currentRecipe = element
                } 
            })
        },
        selectRecipesOffer(){
            let _this = this

            if (!this.selectedFilterId){
                this.recipes = this.recipesAll.filter(item => item.slug != _this.currentRecipe.slug).slice(0, 2)
            } else {
                this.recipes = _this.recipesAll.filter( item => 
                    (parseInt(item.recipe_category_id) === parseInt(_this.selectedFilterId)) && (item.slug != _this.currentRecipe.slug)
                ).slice(0, 2)
            }
            
        },
        setFilteredCategory(item){
            this.selectedFilterId = item.id
            this.selectRecipesOffer()
            this.setGTMFilterType(item.name)
        },
        getProdFamily(){
            let _this = this

            //set the default state
            _this.brothers = []
            _this.currentProdFamily = null

            _this.get('product_category',
                {
                    params: {                          
                        relations:1,                                       
                        where: [
                            ['where','status', '1'],                                                
                            ['where','identifier', _this.$store.state.prodFamiliesId.ovatos_duhaj]
                        ],
                        relations_params:{
                            product:{
                                // select: ['name', 'shop_link', 'slug', 'card_image'],
                                where: [['where', 'status', '1']],
                                order_by:[['sort_order', 'asc'], ['name', 'asc']],
                                relations:1                                
                            }
                        }

                    }
                },
                function(resp){                                                                                
                                        
                    _this.currentProdFamily = resp.data.product_category[0]
                    _this.brothers = resp.data.product_category[0].product.filter( item => item.id != _this.currentRecipe.recipe_category.product.id)
                                                             
                }, function(err){
                    console.log(err);
                    _this.brothers = []
                    _this.currentProdFamily = null
                }
            )
        },       
        addYoutubeLink(){                      
            let youtubelink = 'https://www.youtube.com/embed/' + this.currentRecipe.video + '?autoplay=1'            
            return this.showVideo === true ? youtubelink : ''                                                       
        }, 
        setMetas() {
            this.setMetaTags({
                title: this.currentRecipe.name, 
                desc: this.currentRecipe.meta_description, 
                keywords: this.currentRecipe.meta_keywords, 
                ogType: 'website', 
                ogTitle: this.currentRecipe.name,                         
                ogImage: this.currentRecipe.meta_image, 
                ogImageWidth: null, 
                ogImageHeight: null, 
                ogDesc: this.currentRecipe.meta_description
            })
        },
        setGtmCart(product){
            
             this.GTtrackGA4({
                'event' : 'event',
                'category': 'gasztro_reszletek_oldal',
                'action': 'vasarlas_gombra_kattintas',                
                'page_type': 'gasztro_reszletek',
                'button': 'vasarlas',            
                'clicked_text': product,
                'action_type': 'gomb_kattintas'
            })
        
        },
        setGTMFilterType(filter){            

            this.GTtrackGA4({
                'event' : 'event',
                'category': 'gasztro_reszletek_oldal',
                'action': 'kartyavalaszto_gombra_kattintas',                
                'page_type': 'gasztro_reszletek',
                'button': 'kartyavalaszto',            
                'clicked_text': filter,
                'action_type': 'gomb_kattintas'
            })
        },
        setGTMRecipeAll(){
             this.GTtrackGA4({
                'event' : 'event',
                'category': 'gasztro_reszletek_oldal',
                'action': 'vissza_az_osszes_recepthez_kattintas',                
                'page_type': 'gasztro_reszletek',
                'button': 'vissza_az_osszes_recepthez',                            
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>